<template>
  <ValidationObserver slim v-slot="{ invalid, untouched, handleSubmit }">
    <v-dialog
        :value="showDialog"
        :persistent="false"
        width="600"
        @input="closeDialog"
    >
      <v-card>
        <v-card-title class="headline">
          <span><i class="fa" :class="{'fa-plus-square-o': !currentItem, 'fa-pencil-square-o': currentItem}"></i> {{
              currentItem ? 'Редактирование пользователя структурного подразделения' : 'Добавление пользователя структурного подразделения'
            }}</span>
        </v-card-title>

        <v-card-text>
          <form>
            <v-layout column>
              <ValidationProvider name="name" rules="required" v-slot="{invalid, validated}">
                <v-text-field
                    :error="invalid && validated"
                    label="ФИО *"
                    hide-details="auto"
                    v-model="name"
                ></v-text-field>
              </ValidationProvider>
              <ValidationProvider name="email" rules="required|email" v-slot="{invalid, validated}">
                <v-text-field
                    :error="invalid && validated"
                    label="Email *"
                    hide-details="auto"
                    v-model="email"
                ></v-text-field>
              </ValidationProvider>
              <ValidationProvider name="name" :rules="{regex: /^[0-9+-_() ]{0,}$/, max: 64}" v-slot="{invalid, validated}">
                <v-text-field
                    :error="invalid && validated"
                    label="Телефон"
                    hide-details="auto"
                    v-model="phone_number"
                ></v-text-field>
              </ValidationProvider>
              <ValidationProvider name="structural_subdivision_id" rules="required"
                                  v-slot="{invalid, validated}">
                <v-select
                    v-model="structural_subdivision_id"
                    :items="subdivisionListShort"
                    item-value="id"
                    item-text="name"
                    label="Выбрать структурное подразделение *"
                    hide-details="auto"
                    required
                    :error="invalid && validated"
                ></v-select>
              </ValidationProvider>
              <ValidationProvider name="type" rules="required"
                                  v-slot="{invalid, validated}">
                <v-select
                    v-model="type"
                    :items="types"
                    item-value="id"
                    item-text="name"
                    label="Выбрать тип пользователя *"
                    hide-details="auto"
                    required
                    :error="invalid && validated"
                ></v-select>
              </ValidationProvider>

            </v-layout>
          </form>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <CButton class="mr-3" color="secondary" @click="$emit('close')">Отмена</CButton>
          <CButton :disabled="invalid" @click="handleSubmit(submit)" color="success">
            <i class="fa fa-save mr-2"></i>
            <span>Сохранить</span>
          </CButton>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </ValidationObserver>
</template>

<script>
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import EntityModalMixin from '@/views/grid_elements/mixins/EntityModalMixin';
import api from '@/api';
import {mapActions, mapGetters} from "vuex";

export default {
  name: "subdivision-modal",
  components: {
    ValidationProvider,
    ValidationObserver
  },
  mixins: [EntityModalMixin],
  created() {
    this.fetchSubdivisionListShort();
  },
  data() {
    let state = {
      name: '',
      email: '',
      phone_number: '',
      structural_subdivision_id: null,
      type: null,
      types: [
        {
          id: 1,
          name: 'Руководитель'
        },
        {
          id: 2,
          name: 'Сотрудник'
        }
      ]
    };

    if (this.currentItem) {
      state = {
        ...state,
        ...this.currentItem
      }
    }

    return state;
  },
  methods: {
    ...mapActions('structuralsubdivisions', ['fetchSubdivisionListShort']),
    createEntity: api.structural_subdivisions.createUser,
    updateEntity: api.structural_subdivisions.updateUser,
  },
  computed: {
    ...mapGetters('structuralsubdivisions', ['subdivisionListShort']),
    data() {
      return {
        id: this.id,
        name: this.name,
        email: this.email,
        phone_number: this.phone_number,
        structural_subdivision_id: this.structural_subdivision_id,
        type: this.type
      }
    }
  }
}
</script>
