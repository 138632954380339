<template>
    <div>
        <v-row class="table-actions-row">
            <v-col sm="4" cols="12" class="pl-0">
                <breadcrumbs :items="crumbs" :exact="true" />
            </v-col>
            <v-col sm="8" class="d-flex justify-end align-center">
                <CButton
                    @click="
                        clearCurrentItem();
                        showEntityModal = true;
                    "
                    class="add-product-modal"
                    color="info"
                    ><i class="la la-plus"></i>
                    Добавить пользователя
                </CButton>
            </v-col>
        </v-row>
        <localization :language="'ru-RU'">
            <intl :locale="'ru'">
                <Grid
                    ref="grid"
                    class="structural-subdivision-users"
                    :sortable="true"
                    :filterable="true"
                    :pageable="gridPageable"
                    :filter="filter"
                    @datastatechange="dataStateChange"
                    :take="take"
                    :skip="skip"
                    :sort="sort"
                    :page-size="pageSize"
                    :total="total"
                    :data-items="dataResult"
                    :columns="columns"
                    :expand-field="'expanded'"
                    @expandchange="expandChange"
                    @sortchange="sortChange"
                >
                    <template v-slot:types="{ methods, props }">
                        <dropdownlist
                            class="types-filter"
                            :data-items="typeFilter"
                            :default-item="{ id: null, name: 'Все' }"
                            :text-field="'name'"
                            :data-item-key="'id'"
                            @change="selectType"
                        />
                    </template>
                    <template v-slot:actions="{ props: { dataItem } }">
                        <td>
                            <div class="actionsColumn">
                                <actions-dropdown
                                    :actions="getActions(dataItem)"
                                />
                            </div>
                        </td>
                    </template>
                    <template v-slot:blocked_filter="{ methods, props }">
                        <boolean-filter
                            @change="
                                selectFilter('is_blocked', $event.value.value)
                            "
                        />
                    </template>
                    <template v-slot:blocked="{ props: { dataItem } }">
                        <td>
                            <div>
                                <span
                                    class="m-badge m-badge--wide m-badge--wide"
                                    :class="{
                                        'm-badge--success': dataItem.is_blocked,
                                        'm-badge--danger': !dataItem.is_blocked,
                                    }"
                                >
                                    {{ dataItem.is_blocked ? 'ДА' : 'НЕТ' }}
                                </span>
                            </div>
                        </td>
                    </template>
                    <template v-slot:type="{ props: { dataItem } }">
                        <td>
                            {{
                                dataItem.type === 1
                                    ? 'Руководитель'
                                    : 'Сотрудник'
                            }}
                        </td>
                    </template>
                    <template v-slot:last_order_date="{ props: { dataItem } }">
                        <td>
                            {{
                                dataItem.last_order_date
                                    ? moment(dataItem.last_order_date).format(
                                          'DD.MM.YYYY'
                                      )
                                    : ''
                            }}
                        </td>
                    </template>
                </Grid>
            </intl>
        </localization>
        <subdivision-user-modal
            v-if="showEntityModal"
            :current-item="currentItem"
            :show-dialog="showEntityModal"
            @click:outside="showEntityModal = false"
            @stored="entityStored($event)"
            @close="showEntityModal = false"
        ></subdivision-user-modal>
    </div>
</template>

<script>
    import TableMixin from '@/views/grid_elements/mixins/TableMixin';
    import ResizableTableMixin from '@/views/grid_elements/mixins/ResizableTableMixin';
    import { Grid } from '@progress/kendo-vue-grid';
    import {
        IntlProvider,
        LocalizationProvider,
    } from '@progress/kendo-vue-intl';
    import { DropDownList } from '@progress/kendo-vue-dropdowns';
    import BooleanFilter from '@/views/components/BooleanFilter';
    import ActionsDropdown from '@/views/grid_elements/ActionsDropdown';
    // import ActionsDropdownNew from '@/views/grid_elements/ActionsDropdownMaterial'
    import { showConfirm } from '@/lib/swal2';
    import { showMessage } from '@/lib/toasted';
    import Breadcrumbs from '@/views/components/breadcrumbs';
    import SubdivisionUserModal from '../modals/SubdivisionUserCreateUpdateModal';
    import '@/views/grid_elements/translations';
    import api from '@/api';
    import { mapActions, mapGetters } from 'vuex';
    import { toDataSourceRequestString } from '@progress/kendo-data-query';

    export default {
        name: 'subdivision-users',
        components: {
            Grid: Grid,
            dropdownlist: DropDownList,
            breadcrumbs: Breadcrumbs,
            'boolean-filter': BooleanFilter,
            'subdivision-user-modal': SubdivisionUserModal,
            localization: LocalizationProvider,
            intl: IntlProvider,
            ActionsDropdown,
        },
        mixins: [TableMixin, ResizableTableMixin],
        created() {
            this.fetchSubdivisionListShort();
            this.getData();
        },
        data() {
            return {
                skip: 0,
                take: 10,
                pageSize: 10,
                total: 7,
                gridPageable: { pageSizes: true },
                expandedItems: [],
                columns: [
                    {
                        field: 'id',
                        title: 'Действия',
                        cell: 'actions',
                        filterable: false,
                        sortable: false,
                    },
                    {
                        field: 'name',
                        title: 'Название',
                    },
                    {
                        field: 'email',
                        title: 'E-mail',
                    },
                    {
                        field: 'phone_number',
                        title: 'Телефон',
                    },
                    {
                        field: 'structural_subdivision_name',
                        title: 'Структурное подразделение',
                    },
                    {
                        field: 'type',
                        title: 'Тип пользователя',
                        cell: 'type',
                        filterCell: 'types',
                    },
                    {
                        field: 'is_blocked',
                        title: 'Заблокирован?',
                        filterCell: 'blocked_filter',
                        cell: 'blocked',
                    },
                    {
                        field: 'last_order_date',
                        title: 'Дата последнего заказа',
                        filter: 'date',
                        format: '{0:d}',
                    },
                ],
                typeFilter: [
                    {
                        id: 1,
                        name: 'Руководитель',
                    },
                    {
                        id: 2,
                        name: 'Сотрудник',
                    },
                ],
            };
        },
        methods: {
            ...mapActions('structuralsubdivisions', [
                'fetchSubdivisionListShort',
            ]),
            ...mapActions('account', ['loginAsSubdivisionUser']),
            fetchData: api.structural_subdivisions.getUsers,
            getActions(dataItem) {
                return [
                    {
                        text: 'Редактировать',
                        icon: 'la la-pencil',
                        handler: () => {
                            this.setCurrentItem(dataItem);
                            this.showEntityModal = true;
                        },
                    },
                    {
                        text: 'Удалить',
                        icon: 'la la-trash',
                        handler: () => this.deleteEntity(dataItem),
                    },
                    {
                        text: dataItem.is_blocked
                            ? 'Разблокировать'
                            : 'Заблокировать',
                        icon:
                            'fa ' +
                            (dataItem.is_blocked ? 'fa-unlock' : 'fa-lock'),
                        handler: () => this.toggleBlocked(dataItem),
                    },
                    {
                        text: 'Сбросить пароль',
                        icon: 'fa fa-key',
                        handler: () => this.resetPassword(dataItem),
                    },
                    {
                        text: 'Войти под пользователем',
                        icon: 'fa fa-sign-in',
                        handler: () => this.loginAs(dataItem),
                    },
                ];
            },
            async deleteEntity(item) {
                const { isConfirmed } = await showConfirm(
                    'Вы действительно хотите удалить данный элемент?'
                );

                if (isConfirmed) {
                    const {
                        data: { status, message },
                    } = await api.structural_subdivisions.deleteUser(item.id);
                    showMessage(status, message);

                    await this.getData();
                }
            },
            prepareFetchParams() {
                return [
                    this.page,
                    this.take,
                    toDataSourceRequestString({
                        filter: this.filter,
                        sort: this.sort,
                    }),
                ];
            },
            async toggleBlocked(item) {
                const { id, is_blocked } = item;
                const message = is_blocked
                    ? 'Вы действительно хотите разблокировать данного пользователя структурного подразделения?'
                    : 'Вы действительно хотите заблокировать данного пользователя структурного подразделения?';
                const { isConfirmed } = await showConfirm(message);

                if (isConfirmed) {
                    const {
                        data: { status, message },
                    } = await api.structural_subdivisions.toggleUserProperty(
                        id,
                        'is-blocked'
                    );
                    showMessage(status, message);

                    await this.getData();
                }
            },
            async resetPassword(item) {
                const { id } = item;
                const message =
                    'Вы действительно хотите сгенерировать новый пароль и отправить его на e-mail пользователя?';
                const { isConfirmed } = await showConfirm(message);

                if (isConfirmed) {
                    const {
                        data: { status, message },
                    } = await api.structural_subdivisions.resetUserPassword(id);
                    showMessage(status, message);
                }
            },
            async loginAs(item) {
                const { id } = item;
                const message =
                    'Вы действительно хотите авторизоваться под пользователем структурного подразделения?';
                const { isConfirmed } = await showConfirm(message);

                if (isConfirmed) {
                    await this.loginAsSubdivisionUser(id);
                }
            },
            selectType({ value }) {
                if (value.id) {
                    this.setFilter('type', 'eq', value.id);
                } else {
                    this.removeFilter('type');
                }

                this.updateTable(true);
            },
            selectSubdivision({ value }) {
                if (value.id) {
                    this.setFilter('structural_subdivision_id', 'eq', value.id);
                } else {
                    this.removeFilter('structural_subdivision_id');
                }

                this.updateTable(true);
            },
        },
        computed: {
            ...mapGetters('structuralsubdivisions', ['subdivisionListShort']),
        },
    };
</script>

<style scoped></style>
